<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-input type="text" placeholder="请输入职业名称" v-model="query.name"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="onAdd" v-if="permission.saveOccupation">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'id'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="id" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="name" label="职业名称" align="center"></el-table-column>
                <el-table-column label="操作" width="110px" align="center">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateOccupation">
                            <el-button class="success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.occupationRemove">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看职业信息详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

        <!-- 添加职业信息 -->
        <el-dialog :title="`${editForm.id ? '编辑' : '添加'}职业信息`" :visible.sync="addOrEditDialog" :modal="false"
            :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
            <el-form label-width="140px" ref="editForm" :model="editForm" :rules="addRules">
                <el-form-item label="职业名称" prop="name">
                    <el-input size="medium" v-model="editForm.name" placeholder="请输入职业名称"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import userManager from '@/request/userManager.js'
import { occupationModule } from "@/request/userManager.js";
import ShowContent from '@/components/common/subgroup/ShowContent.vue'
export default {
    mixins: [getList],
    components: {
        ShowContent,
    },
    data() {
        return {
            requst: {
                module: userManager,
                url: 'occupationList',
                addUrl: '',
                updateUrl: '',
            },
            dialogSeeContent: false,
            rowInfo: {},
            query: {
                name: '', //搜索词
            },
            addOrEditDialog: false,
            addOrEditBtnLoading: false,
            editForm: {
                id: 0,
                name: '',
            },
            addRules: {
                name: [
                    { required: true, message: '请输入职业名称', trigger: 'blur' },
                ],
            },
        }
    },
    created() { },
    methods: {
        hoverContent(row) {
            this.rowInfo = row
            this.dialogSeeContent = true
        },

        // 点击添加
        onAdd() {
            this.addOrEditDialog = true
        },
        // 点击添加---取消
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        // 点击添加---确定
        clickAddOrEditConfirm() {
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    this.addOrEditBtnLoading = true
                    let dataValue = JSON.parse(JSON.stringify(this.editForm))
                    if (this.editForm.id > 0) {
                        await occupationModule.updateOccupation(dataValue).then((response) => {
                            this.addOrEditBtnLoading = false;
                            switch (response.data.code) {
                                case 200:
                                    this.$message({ message: response.data.message, type: 'success', })
                                    this.$emit('success')
                                    this.refresCurrentPagehData()
                                    this.resetAddOrEditData()
                                    break;
                                default:
                                    this.$message({
                                        message: response.data.message,
                                        type: 'error'
                                    });
                                    // this.$message.error(response.data.message);
                                    break;
                            }
                        }).catch((error) => {
                            //
                            this.addOrEditBtnLoading = false;
                        });
                    } else {
                        await occupationModule.saveOccupation(dataValue).then((response) => {
                            this.addOrEditBtnLoading = false;
                            switch (response.data.code) {
                                case 200:
                                    this.$message({ message: response.data.message, type: 'success', })
                                    this.$emit('success')
                                    this.refresCurrentPagehData()
                                    this.resetAddOrEditData()
                                    break;
                                default:
                                    this.$message({
                                        message: response.data.message,
                                        type: 'error'
                                    });
                                    // this.$message.error(response.data.message);
                                    break;
                            }
                        }).catch((error) => {
                            //
                            this.addOrEditBtnLoading = false;
                        });
                    }
                }
            })
        },

        // 清除添加数据
        resetAddOrEditData() {
            this.addOrEditDialog = false
            this.editForm = {
                id: 0,
                name: '',
                orderWeight: 9,
            }
        },
        // 点击编辑信息
        async clickEditInfo(row) {
            this.editForm = {
                id: row.id,
                name: row.name,
                orderWeight: row.orderWeight,
            }
            this.addOrEditDialog = true
        },

        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await userManager.occupationRemove({
                    id: row.id,
                })
                if (code === 200) {
                    this.$message({
                        message: message,
                        type: 'success',
                    })
                    this.delRefresh(index)
                }
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>

<style scoped></style>